import React, { useEffect, useState } from 'react';
import './searchStyles.css';
import { partitionByVillageFatherMatch } from '../../helpers/searchUtils';

const SearchResultTable = ({
  searchResults = [],
  onClose,
  setSelectedUser,
  noneOfThese,
  selectButton = true,
  searchParams,
  village,
  onNoneOfTheseClick,
  closeOnClick = true,
}) => {
  const [sortedSearchResult, setSortedSearchResult] = useState({
    nameListFatherMatch: [],
    fatherMatchedList: [],
    nameListVillageMatch: [],
    villageMatchedList: [],
    nameList: [],
    remainingSearchResultsSortedByVillageList: [],
  });

  useEffect(() => {
    const sortedResults = partitionByVillageFatherMatch(searchResults, searchParams, village);
    setSortedSearchResult(sortedResults);
  }, []);

  const renderSearchParams = () => {
    return Object.entries(searchParams).map(([key, value]) => (
      <div key={key}>
        <strong>{key}:</strong> {value}
      </div>
    ));
  };

  const renderSection = (title, results) => (
    <>
      {results.length > 0 && (
        <>
          <h4>{title}</h4>
          <table>
            <thead>
              <tr>
                {selectButton && <th>Select</th>}
                <th>Name</th>
                <th>Father Name</th>
                {searchParams?.gender === 'female' && <th>Husband Name</th>}
                <th>Gautra</th>
                <th>Village</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={index}>
                  {selectButton && (
                    <td>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedUser(result);
                          if (closeOnClick) onClose();
                        }}
                      >
                        Select
                      </button>
                    </td>
                  )}
                  <td>
                    <a target="_blank" href={`/family-tree?idOfUser=${result.uid}`}>
                      {result?.name}
                    </a>
                  </td>
                  <td>{result?.father_name}</td>
                  {result?.gender === 'female' && (
                    <td>{result?.husband_full_name ? `${result?.husband_full_name}` : ''}</td>
                  )}
                  <td>{result?.subcaste ? `${result?.subcaste} (${result?.gautra})` : result?.gautra}</td>
                  <td>{result?.village}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );

  return (
    <div>
      {sortedSearchResult.nameListFatherMatch.length > 0 ||
      sortedSearchResult.fatherMatchedList.length > 0 ||
      sortedSearchResult.nameListVillageMatch.length > 0 ||
      sortedSearchResult.villageMatchedList.length > 0 ||
      sortedSearchResult.nameList.length > 0 ||
      sortedSearchResult.remainingSearchResultsSortedByVillageList.length > 0 ? (
        <>
          <h2>Results are shown here:</h2>
          <div>{renderSearchParams()}</div>
          {renderSection('Name and Father Name Match', sortedSearchResult.nameListFatherMatch)}
          {renderSection('Father Name Match', sortedSearchResult.fatherMatchedList)}
          {renderSection('Name and Village Match', sortedSearchResult.nameListVillageMatch)}
          {renderSection('Village Match', sortedSearchResult.villageMatchedList)}
          {renderSection('Name Match', sortedSearchResult.nameList)}
          {renderSection(
            'Remaining Results (Sorted by Village)',
            sortedSearchResult.remainingSearchResultsSortedByVillageList,
          )}
          {noneOfThese && (
            <button
              onClick={() => {
                if (!closeOnClick) {
                  onNoneOfTheseClick();
                } else if (onNoneOfTheseClick) {
                  onClose();
                  onNoneOfTheseClick(searchParams);
                } else onClose();
              }}
            >
              None Of These
            </button>
          )}
        </>
      ) : (
        <div className="no-results-container">
          <p className="no-results-message">No results found.</p>
          <button
            className="create-profile-button"
            onClick={() => {
              if (onNoneOfTheseClick) {
                onClose();
                onNoneOfTheseClick(searchParams);
              } else onClose();
            }}
          >
            Create Independent Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchResultTable;
