import { getApiWrapper, postApiWrapper } from "./token-wrapper-function";

export const getFamilyInfo = async (nodeId)  => {
    const url = `/api/tasks/run_actions.php`;
    const data = {
        "action": "get_family_details",
        "person_node_id": nodeId,
        "mode": "json"
    }
    const result = await postApiWrapper(url, data);
    return result;
}

export const updateFamilyField = async (data) => {
    const url = `/api/family/update_field`;
    const result = await postApiWrapper(url, data);
    return result;
}

export const getCompleteDetails = async (node_id) =>{
    const url = `/api/user_info?node_id=${node_id}`
    const result = await getApiWrapper(url);
    return result;

}

export const familyDetailsByFamilyId = async(family_id) =>{
    const url  = `/api/family_tree/${family_id}`;
    const result = await getApiWrapper(url);
    return result;
}

export const relativeFamilyDetailsByNodeId = async(node_id, family_type,tree_degree) =>{
    const url  = `/api/relative_family_tree/${node_id}?family_type=${family_type}&tree_degree=${tree_degree}`;
    const result = await getApiWrapper(url);
    return result;
}


export const partialDetailsApi = async (node_id, data) => {
    const url = `/api/node/${node_id}/metadata`;
    const result = await postApiWrapper(url, data);
    return result;
}
